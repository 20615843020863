import {
  getSortedHours,
  getSurchargePricing,
  isTimeSlotInSelectedDay,
  isTimeslotInTheFuture,
  parseDay,
  type SurchargeType,
  useCookieStore,
  useNavigationStore,
} from '@booking/shared';
import { getDateInTimezone, getDateTypeOfDay } from '@booking/shared';
import { Actions, Locales, TimeSlot } from '@booking/types';
import { SpinnerWrapper } from '@booking/ui-web';
import useAnalytics from '@hooks/useAnalytics';
import { useTranslation } from 'next-i18next';
import posthog from 'posthog-js';
import { useMemo } from 'react';

import DynamicPricingInfo from './DynamicPricingInfo';
import NextAvailableDateButton from './NextAvailableDateButton';
import NoAvailableTimeSlotsBanner from './NoAvailableSlotsBanner';
import TimeButtons from './TimeButtons';

type TimePickerProps = {
  locale?: Locales;
  timeSlots: TimeSlot[];
  selectedDate: string;
  isLoading?: boolean;
  daysAhead: number;
  nextAvailableTimeSlot?: { startTime: string };
  setSelectedDate?: (date: string) => void;
  onClick: (time?: string, practitionerId?: string) => void;
  practitionerId?: string;
  needsDynamicPricing: boolean;
  price?: number;
};

export default function TimePicker({
  timeSlots,
  selectedDate,
  isLoading,
  practitionerId,
  onClick,
  setSelectedDate,
  nextAvailableTimeSlot,
  locale = 'nb',
  needsDynamicPricing,
  price,
}: TimePickerProps) {
  const now = new Date();
  const { t } = useTranslation(['common', 'booking']);
  const setEventProps = useAnalytics();
  const { queries } = useNavigationStore((state) => ({
    setQueries: state.setQueries,
    queries: state.queries,
  }));

  const isPosthogEnabled = useCookieStore((state) => state.isPosthogEnabled);

  const availableTimeSlotsOnSelectedDay = timeSlots.filter(({ startTime }) => {
    return (
      isTimeslotInTheFuture(startTime, now) &&
      isTimeSlotInSelectedDay(startTime, parseDay(selectedDate))
    );
  });

  const sortedHours = useMemo(() => {
    return getSortedHours(availableTimeSlotsOnSelectedDay || []);
  }, [availableTimeSlotsOnSelectedDay]);

  const hours = useMemo(() => {
    // If there are no available time slots on the
    // selected day, show the next available time slot
    const availableHours = Object.entries(sortedHours).filter((period) => {
      const isAvailable =
        period[1].length > 0 &&
        period[1].some((slot) => {
          return slot.isAvailable;
        });
      return isAvailable;
    });

    return availableHours;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortedHours]);

  const nextAvailableTest =
    isPosthogEnabled &&
    posthog?.getFeatureFlag('practitioner-next-available-v2') === 'test';

  const nextAvailableDate =
    nextAvailableTimeSlot &&
    getDateInTimezone(new Date(nextAvailableTimeSlot.startTime));

  const nextAvailableOnClick = (
    setSelectedDate: (date: string) => void,
    nextAvailableDate: string,
  ) => {
    setSelectedDate(nextAvailableDate);
    window.scrollTo(0, 0);
    setEventProps({
      event: {
        action: Actions.ClickNextAvailable,
        practitionerId: practitionerId,
        nextAvailableDate,
        selectedDate,
      },
    });
  };

  const typeOfDay = getDateTypeOfDay(selectedDate);

  const getSurcharge = (
    period: string,
  ): { surchargePricing: number; surchargeType: SurchargeType | undefined } => {
    if (!needsDynamicPricing)
      return { surchargePricing: 0, surchargeType: undefined };

    // Weekend/Holiday surcharge take precedence,
    const surchargePricing = getSurchargePricing(queries.service, typeOfDay);
    // if there is none we check for evening pricing
    if (surchargePricing === 0 && period === 'evening') {
      return {
        surchargePricing: getSurchargePricing(queries.service, period),
        surchargeType: period,
      };
    }
    if (typeOfDay === 'day') {
      return { surchargePricing, surchargeType: undefined };
    }

    return { surchargePricing, surchargeType: typeOfDay };
  };

  return (
    <div className="w-full">
      <div className="flex h-full flex-col items-center justify-center gap-8">
        {isLoading ? (
          <SpinnerWrapper />
        ) : (
          hours.map((period) => {
            const { surchargePricing, surchargeType } =
              price === 0
                ? { surchargePricing: 0, surchargeType: undefined }
                : getSurcharge(period[0]);
            return (
              <div
                key={period[0]}
                className="flex w-full flex-col justify-start"
              >
                <div className="flex flex-col gap-4">
                  <span>{t(period[0])}</span>
                  {surchargePricing > 0 && surchargeType && (
                    <DynamicPricingInfo
                      price={price ?? 0}
                      surcharge={surchargePricing}
                      surchargeType={surchargeType}
                      className="text-xs"
                    />
                  )}
                  <TimeButtons
                    timeSlots={period[1]}
                    onClick={(time) => onClick(time, practitionerId)}
                  />
                </div>
              </div>
            );
          })
        )}
        {hours.length === 0 &&
          !isLoading &&
          (setSelectedDate && nextAvailableDate && nextAvailableTest ? (
            <NextAvailableDateButton
              nextAvailableDate={nextAvailableDate}
              locale={locale}
              onClick={() =>
                nextAvailableOnClick(setSelectedDate, nextAvailableDate)
              }
            />
          ) : (
            <NoAvailableTimeSlotsBanner />
          ))}
      </div>
    </div>
  );
}
