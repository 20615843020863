import {
  isPhoneNumberGoodEnough,
  useBannerStore,
  useCookieStore,
  useNavigationStore,
  usePersonalDataStore,
  useServiceTreeStore,
} from '@booking/shared';
import { Actions, PatientInfo } from '@booking/types';
import {
  CustomPhoneInput,
  InputError,
  StepWrapper,
  TermsOfService,
} from '@booking/ui-web';
import BookingSummary from '@components/BookingSummary';
import { Button, CheckBox } from '@drdropin-tech/theseus';
import useAnalytics from '@hooks/useAnalytics';
import { createNewsletterSubscription } from '@utils/newletter';
import classNames from 'classnames';
import Cleave from 'cleave.js/react';
import { isAfter, isBefore, isValid, parse } from 'date-fns';
import { nb } from 'date-fns/locale';
import { getCountryCallingCode } from 'libphonenumber-js';
import { CountryCode } from 'libphonenumber-js/types';
import { useTranslation } from 'next-i18next';
import posthog from 'posthog-js';
import { useEffect, useState } from 'react';
import {
  Controller,
  DeepMap,
  FieldError,
  SubmitHandler,
  useForm,
} from 'react-hook-form';

const NEWSLETTER_TEST = 'newsletter-ab-test';

export default function SelectPersonalDetailsFormStep() {
  const setEventProps = useAnalytics();
  const { t } = useTranslation(['booking', 'common']);
  const [selectedCountry, setSelectedCountry] = useState<CountryCode>('NO');
  const isPosthogEnabled = useCookieStore((state) => state.isPosthogEnabled);
  const newsletterTest =
    isPosthogEnabled && posthog?.getFeatureFlag(NEWSLETTER_TEST) === 'test';

  // Close the feedback form banner, in case it was opened in the previous step.
  const closeBanner = useBannerStore((state) => state.closeBanner);
  useEffect(() => {
    closeBanner();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { advanceStep, queries } = useNavigationStore((state) => ({
    advanceStep: state.advanceStep,
    queries: state.queries,
  }));

  const { personalDetails, setPersonalDetails } = usePersonalDataStore()(
    (state) => ({
      personalDetails: state.personalDetails,
      setPersonalDetails: state.setPersonalDetails,
    }),
  );

  const { serviceMap } = useServiceTreeStore((state) => ({
    serviceMap: state.serviceMap,
  }));

  const [acceptedTerms, setAcceptedTerms] = useState(
    queries.partner !== 'storebrand',
  );

  const serviceFromMap = serviceMap.get(queries.service);

  const {
    control,
    formState: { errors },
    handleSubmit,
    watch,
  } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    shouldFocusError: true,
    defaultValues: {
      ...personalDetails,
    },
  });

  const onSubmit: SubmitHandler<PatientInfo> = ({
    name,
    birthdate,
    description,
    newsletter_email,
    newsletter_consent,
    phone,
  }) => {
    const partnerInfo = serviceFromMap?.membershipCampaignId
      ? {
          partner: queries.partner,
          partnerId: queries.partnerId,
          membershipCampaignId: serviceFromMap?.membershipCampaignId,
        }
      : null;

    const newsletterInfo = newsletterTest
      ? {
          newsletter_email,
          newsletter_consent,
        }
      : {};

    setPersonalDetails({
      name,
      birthdate,
      phone,
      description,
      countryCode: `+${getCountryCallingCode(selectedCountry)}`,
      selectedCountry,
      ...partnerInfo,
      ...newsletterInfo,
    });
    setEventProps({
      event: {
        action: Actions.SelectPersonalDetails,
        vertical: queries.vertical,
        speciality: queries.speciality,
        service: queries.service,
        city: queries.city,
        clinic: queries.clinicId,
      },
    });

    // fire and forget newsletter subscription
    if (newsletterTest && newsletter_consent) {
      if (newsletter_email && newsletter_email?.length > 0) {
        setEventProps({
          event: {
            action: Actions.NewsletterSubmitted,
            vertical: queries.vertical,
            speciality: queries.speciality,
            service: queries.service,
            city: queries.city,
            clinic: queries.clinicId,
          },
        });
      } else {
        setEventProps({
          event: {
            action: Actions.NewsletterMissing,
            vertical: queries.vertical,
            speciality: queries.speciality,
            service: queries.service,
            city: queries.city,
            clinic: queries.clinicId,
          },
        });
      }

      createNewsletterSubscription({
        name,
        email: newsletter_email,
        consent: newsletter_consent ?? false,
      })
        .then(() => {})
        .catch(console.error);
    }
    advanceStep();
  };

  const onError = (errors: DeepMap<PatientInfo, FieldError>) =>
    console.log(errors);

  //#region Validation
  const validDate = (date?: string): [boolean, string] => {
    if (!date) return [false, 'min_length'];
    const parsedDate = parse(date, 'dd/MM/yyyy', new Date(), { locale: nb });
    if (parsedDate) {
      const validDate = isValid(parsedDate);
      if (!validDate) {
        return [false, 'min_length'];
      }

      const isBeforeToday = isBefore(parsedDate, new Date());
      if (!isBeforeToday) {
        setEventProps({
          event: {
            action: Actions.FutureDateError,
          },
          target: {
            posthog: true,
          },
        });
        return [false, 'before_today'];
      }

      const isAfterThe20thCentury = isAfter(parsedDate, new Date(1900, 0, 1));
      if (!isAfterThe20thCentury) {
        setEventProps({
          event: {
            action: Actions.PastDateError,
          },
          target: {
            posthog: true,
          },
        });
        return [false, 'too_old'];
      }
      return [true, ''];
    }

    return [true, ''];
  };

  const changeSelectedCountry = (country: CountryCode) => {
    setSelectedCountry(country);
  };

  const validPhoneNumber = (input: string, selected: CountryCode) => {
    if (input && selected) {
      return isPhoneNumberGoodEnough(input, selected);
    }

    return false;
  };
  //#endregion

  return (
    <form
      onSubmit={handleSubmit(onSubmit, onError)}
      className="mx-auto max-w-[446px] pb-20"
    >
      <StepWrapper
        title={t('steps.personal_details.title', { ns: 'booking' })}
        className="gap-6"
        stepWidth="max-w-[512px]"
      >
        <BookingSummary
          hasClinic={true}
          hasTimeStamp={true}
          practitionerId={queries.practitionerId}
        />

        <div className="flex w-full flex-col gap-7">
          {/* NAME INPUT START */}
          <Controller
            render={({
              fieldState: { isDirty },
              field: { value, onChange, name, onBlur, ref },
            }) => {
              return (
                <div className="flex w-full flex-col gap-2">
                  <label htmlFor={name}>
                    {t('steps.personal_details.input.label.name', {
                      ns: 'booking',
                    })}
                  </label>
                  <div className="relative">
                    <input
                      name={name}
                      value={value ?? ''}
                      onChange={(e) => {
                        onChange(e);
                        return e.target.value.replace(/\d/g, '');
                      }}
                      type="text"
                      className={classNames(
                        { 'focus-input-error': errors.name?.message },
                        'input border-accent focus-input w-full rounded-lg border-2 text-black',
                      )}
                      placeholder={t(
                        'steps.personal_details.input.placeholder.name',
                      )}
                      onBlur={onBlur}
                      ref={ref}
                      maxLength={100}
                      data-testid={name}
                      autoFocus={true}
                    />

                    <InputError
                      isDirty={isDirty}
                      errors={errors.name as FieldError}
                    />
                  </div>
                  {errors.name?.message && (
                    <div className="rounded-lg bg-[#FFE2E0] p-3 text-xs leading-6 text-black">
                      {errors?.name?.message as string}
                    </div>
                  )}
                </div>
              );
            }}
            control={control}
            name="name"
            rules={{
              required: {
                value: true,
                message: t('steps.personal_details.input.error.name.required'),
              },
              pattern: /[A-Za-z]/,
              minLength: {
                value: 1,
                message: t(
                  'steps.personal_details.input.error.name.min_length',
                ),
              },
              maxLength: {
                value: 100,
                message: t(
                  'steps.personal_details.input.error.name.max_length',
                ),
              },
            }}
          />
          {/* NAME INPUT END */}
          {/* DATE OF BIRTH INPUT START */}
          <Controller
            render={({
              fieldState: { isDirty },
              field: { value, name, onChange, onBlur, ref },
            }) => {
              return (
                <div className="flex w-full flex-col gap-2">
                  <label htmlFor={name}>
                    {t('steps.personal_details.input.label.patient_birthdate')}
                  </label>
                  <div className="relative">
                    <Cleave
                      options={{ date: true, datePattern: ['d', 'm', 'Y'] }}
                      className={classNames(
                        { 'focus-input-error': errors.birthdate?.message },
                        'input border-accent focus-input w-full rounded-lg border-2 text-black',
                      )}
                      value={value}
                      onChange={(e) => {
                        onChange(e);
                      }}
                      name={name}
                      onBlur={onBlur}
                      placeholder={t(
                        'steps.personal_details.input.placeholder.patient_birthdate',
                      )}
                      htmlRef={ref}
                      onInit={() => ''}
                      inputMode="numeric"
                      data-testid={name}
                      autoComplete="bday"
                    />

                    <InputError
                      isDirty={isDirty}
                      errors={errors.birthdate as FieldError}
                    />
                  </div>
                  {errors.birthdate && (
                    <div className="rounded-lg bg-[#FFE2E0] p-3 text-xs leading-6 text-black">
                      {String(errors.birthdate?.message)}
                    </div>
                  )}
                </div>
              );
            }}
            name="birthdate"
            control={control}
            rules={{
              required: {
                value: true,
                message: t(
                  'steps.personal_details.input.error.birthdate.required',
                ),
              },
              minLength: {
                value: 10,
                message: t(
                  'steps.personal_details.input.error.birthdate.min_length',
                ),
              },
              validate: (value) => {
                const [isValid, errorKey] = validDate(value);
                if (!isValid && errorKey)
                  return t(
                    `steps.personal_details.input.error.birthdate.${errorKey}`,
                  );
                return isValid;
              },
            }}
          />
          {/* DATE OF BIRTH INPUT END */}
          {/* PHONE NUMBER INPUT START */}
          <Controller
            render={({
              fieldState: { isDirty },
              field: { value, name, onBlur, onChange },
            }) => {
              return (
                <div className="flex w-full flex-col gap-2">
                  <CustomPhoneInput
                    name={name}
                    label={t('steps.personal_details.input.label.phone_number')}
                    defaultCountry={selectedCountry}
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                    changeSelectedCountry={changeSelectedCountry}
                    selectedCountry={selectedCountry as CountryCode}
                    isDirty={isDirty}
                    errors={errors.phone as FieldError}
                    i18nFunction={t}
                  />
                </div>
              );
            }}
            name="phone"
            control={control}
            rules={{
              required: {
                value: true,
                message: t('steps.personal_details.input.error.phone.required'),
              },
              validate: {
                validPhoneNumber: (input) => {
                  const isValid =
                    input && !validPhoneNumber(input, selectedCountry)
                      ? t('steps.personal_details.input.error.phone.min_length')
                      : true;
                  return isValid;
                },
              },
            }}
          />
          {/* PHONE NUMBER INPUT END */}
          {/* DESCRIPTION INPUT START */}
          <Controller
            render={({
              fieldState: { isDirty },
              field: { value, name, onBlur, onChange, ref },
            }) => (
              <div className={'relative flex w-full flex-col gap-2'}>
                <label htmlFor={name}>
                  {t('steps.personal_details.input.label.description', {
                    ns: 'booking',
                  })}
                </label>
                <div className="relative flex">
                  <textarea
                    name={name}
                    value={value}
                    className={classNames(
                      { 'focus-input-error': errors.description?.message },
                      'textarea border-accent focus-input min-h-[140px] w-full rounded-lg border-2 text-black',
                    )}
                    placeholder={t(
                      'steps.personal_details.input.placeholder.text_area',
                    )}
                    onChange={onChange}
                    onBlur={onBlur}
                    ref={ref}
                    maxLength={500}
                    data-testid={name}
                  />
                  <InputError
                    isDirty={isDirty}
                    errors={errors.description as FieldError}
                  />
                </div>
                {errors.description && (
                  <div className="rounded-lg bg-[#FFE2E0] p-3 text-xs leading-6 text-black">
                    {errors.description.message as string}
                  </div>
                )}
              </div>
            )}
            name="description"
            control={control}
            rules={{
              required: {
                value: true,
                message: t(
                  'steps.personal_details.input.error.description.required',
                ),
              },
              minLength: {
                value: 3,
                message: t(
                  'steps.personal_details.input.error.description.min_length',
                ),
              },
              maxLength: {
                value: 500,
                message: t(
                  'steps.personal_details.input.error.description.max_length',
                ),
              },
            }}
          />
          {/* DESCRIPTION INPUT END */}

          {/* CUSTOMER NEWSLETTER CONSENT START */}
          {newsletterTest && (
            <Controller
              render={({ field: { value, onChange, name, onBlur } }) => {
                return (
                  <div className="flex w-full flex-col gap-2">
                    <CheckBox
                      checked={value ?? false}
                      classname="-mt-2 bg-white border-none checked:border-none checked:ring-2 checked:ring-yellow-500 checked:ring-opacity-50 checked:ring-offset-2 checked:ring-offset-white checked:ring-offset-opacity-50"
                      onChange={(event) => {
                        if (!value && event.target.checked) {
                          setEventProps({
                            event: {
                              action: Actions.NewsletterAccept,
                              vertical: queries.vertical,
                              speciality: queries.speciality,
                              service: queries.service,
                              city: queries.city,
                              clinic: queries.clinicId,
                            },
                          });
                        } else if (!!value && !event.target.checked) {
                          setEventProps({
                            event: {
                              action: Actions.NewsletterReject,
                              vertical: queries.vertical,
                              speciality: queries.speciality,
                              service: queries.service,
                              city: queries.city,
                              clinic: queries.clinicId,
                            },
                          });
                        }
                        onChange(event);
                      }}
                      name={name}
                      onBlur={onBlur}
                      label={t(
                        'steps.personal_details.input.label.newsletter_consent',
                        {
                          ns: 'booking',
                        },
                      )}
                      labelProps={{
                        className:
                          'flex-row-reverse justify-end flex cursor-pointer label gap-4 text-[17px] p-0',
                      }}
                    />
                  </div>
                );
              }}
              control={control}
              name="newsletter_consent"
            />
          )}
          {/* CUSTOMER NEWSLETTER CONSENT END */}

          {/* CUSTOMER NEWSLETTER EMAIL START */}
          {watch('newsletter_consent') === true && (
            <Controller
              render={({
                fieldState: { isDirty },
                field: { value, onChange, name, onBlur, ref },
              }) => {
                return (
                  <div className="flex w-full flex-col gap-2">
                    <label htmlFor={name}>
                      {t(
                        'steps.personal_details.input.label.newsletter_email',
                        {
                          ns: 'booking',
                        },
                      )}
                    </label>
                    <div className="relative">
                      <input
                        name={name}
                        value={value ?? ''}
                        onChange={onChange}
                        type="text"
                        className={classNames(
                          {
                            'focus-input-error':
                              errors.newsletter_email?.message,
                          },
                          'input border-accent focus-input w-full rounded-lg border-2 text-black',
                        )}
                        placeholder={t(
                          'steps.personal_details.input.placeholder.newsletter_email',
                        )}
                        onBlur={onBlur}
                        ref={ref}
                      />
                      <InputError
                        isDirty={isDirty}
                        errors={errors.newsletter_email as FieldError}
                      />
                    </div>
                    {errors.newsletter_email?.message && (
                      <div className="rounded-lg bg-[#FFE2E0] p-3 text-xs leading-6 text-black">
                        {errors?.newsletter_email?.message as string}
                      </div>
                    )}
                  </div>
                );
              }}
              control={control}
              name="newsletter_email"
              rules={{
                required: false,
                pattern: {
                  value: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
                  message: t(
                    'steps.personal_details.input.error.newsletter_email.pattern',
                  ),
                },
              }}
            />
          )}
          {/* CUSTOMER NEWSLETTER EMAIL END */}

          {queries.partner === 'storebrand' && (
            <TermsOfService
              checked={acceptedTerms}
              onChange={setAcceptedTerms}
              i18nFunction={t}
            />
          )}

          <div className="w-full pt-8">
            <Button
              type="submit"
              appearance="accent"
              disabled={!acceptedTerms}
              className="w-full font-normal normal-case"
              data-testid="submit"
            >
              {t('next', { ns: 'common' })}
            </Button>
          </div>
        </div>
      </StepWrapper>
    </form>
  );
}
